<script lang="ts">
  let clazz = "";
  export { clazz as class };
</script>

<svg
  width="43"
  height="25"
  viewBox="0 0 43 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class={clazz}
>
  <rect
    x="3.72656"
    y="2.63037"
    width="34.8511"
    height="2.41108"
    fill="currentColor"
  />
  <circle cx="3.72622" cy="3.72622" r="3.72622" fill="currentColor" />
  <circle cx="38.5768" cy="3.72622" r="3.72622" fill="currentColor" />
  <rect
    x="7.45312"
    y="11.1787"
    width="27.3987"
    height="2.41108"
    fill="currentColor"
  />
  <circle cx="7.45278" cy="12.2746" r="3.72622" fill="currentColor" />
  <circle cx="34.8512" cy="12.2746" r="3.72622" fill="currentColor" />
  <rect
    x="10.7393"
    y="19.7271"
    width="20.823"
    height="2.41108"
    fill="currentColor"
  />
  <circle cx="11.1784" cy="20.8229" r="3.72622" fill="currentColor" />
  <circle cx="31.1247" cy="20.8229" r="3.72622" fill="currentColor" />
</svg>
