<script lang="ts">
  import { fade, fly } from "svelte/transition";
  import { linear, cubicInOut } from "svelte/easing";
  import BrandLogo from "../icons/BrandLogo.svelte";
  import CloseIcon from "../icons/CloseIcon.svelte";
  import MenuIcon from "../icons/MenuIcon.svelte";

  let clazz = "";
  export { clazz as class };

  let menuIsOpen = false;

  const toggleMenu = () => (menuIsOpen = !menuIsOpen);
</script>

<div id="mobile-header" class="w-full mt-6 flex items-center {clazz}">
  <div class="w-1/4">
    <button type="button" on:click={toggleMenu} class="ml-6">
      <MenuIcon class="h-5 text-white" />
    </button>
  </div>
  <div class="w-1/2 flex justify-center">
    <BrandLogo class="h-20 text-white" />
  </div>
</div>

{#if menuIsOpen}
  <div
    id="mobile-menu"
    class="relative z-[100] lg:hidden"
    role="dialog"
    aria-modal="true"
  >
    <div
      id="mobile-menu-background-overlay"
      class="fixed inset-0 bg-gray-900 bg-opacity-50"
      transition:fade={{ duration: 300, easing: linear }}
    />

    <div class="fixed inset-0 flex z-[100]">
      <div
        class="relative max-w-xs w-full bg-green-logo mr-20 py-12 px-6"
        transition:fly={{ x: -320, duration: 300, easing: cubicInOut }}
      >
        <button
          type="button"
          on:click={toggleMenu}
          class="focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        >
          <span class="sr-only">Close sidebar</span>
          <CloseIcon class="h-7 w-7 text-black" />
        </button>

        <nav class="mt-8 grid grid-cols-1 gap-4">
          <a href="/" class="uppercase font-black underline tracking-wider"
            >Home</a
          >
          <a
            href="/services"
            class="uppercase font-black underline tracking-wider">Services</a
          >
          <a
            href="/technology"
            class="uppercase font-black underline tracking-wider">Technology</a
          >
          <a
            href="/company"
            class="uppercase font-black underline tracking-wider">Company</a
          >
          <a
            href="/contact"
            class="uppercase font-black underline tracking-wider">Book a Demo</a
          >
        </nav>
      </div>
    </div>
  </div>
{/if}
