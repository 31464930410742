<script lang="ts">
  let clazz = "";
  export { clazz as class };
</script>

<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 1600 1600"
  class={clazz}
>
  <g>
    <g>
      <path fill="currentColor" d="M249.1,1099.4V1427h-51v-327.5H249.1z"></path>
      <path
        fill="currentColor"
        d="M269.7,1099.4h50.2l31.3,143.6v-143.6h48V1427H349l-30.9-147.5V1427h-48.4V1099.4z"
      ></path>
      <path
        fill="currentColor"
        d="M534.2,1099.4v45.4h-35.2V1427h-50.6v-282.1h-35.6v-45.4H534.2z"
      ></path>
      <path
        fill="currentColor"
        d="M646.5,1099.4v45.4h-49.3v81.5h38.2v46.7h-38.2v108H647v45.9H546.6v-327.5H646.5z"
      ></path>
      <path
        fill="currentColor"
        d="M712.5,1099.4v281.7h37.7v45.9h-88.7v-327.5H712.5z"
      ></path>
      <path
        fill="currentColor"
        d="M811.1,1099.4v281.7h37.7v45.9h-88.7v-327.5H811.1z"
      ></path>
      <path fill="currentColor" d="M910.6,1099.4V1427h-51v-327.5H910.6z"></path>
      <path
        fill="currentColor"
        d="M1045.2,1099.4v45.4h-35.2V1427h-50.6v-282.1h-35.6v-45.4H1045.2z"
      ></path>
      <path fill="currentColor" d="M1109.5,1099.4V1427h-51v-327.5H1109.5z"
      ></path>
      <path
        fill="currentColor"
        d="M1252.3,1161.6v202.8c0,44.2-23.6,67.3-61.3,67.3c-37.3,0-60.9-22.7-60.9-66.9V1162
			c0-44.6,22.7-66.9,60.4-66.9C1228.3,1095.1,1252.3,1117,1252.3,1161.6z M1180.3,1166.3v194.2c0,21.9,4.3,25.3,10.7,25.3
			c6.9,0,10.3-3.4,10.3-25.3v-194.2c0-22.3-3.4-26.2-10.7-26.2C1184.1,1140.2,1180.3,1144,1180.3,1166.3z"
      ></path>
      <path
        fill="currentColor"
        d="M1272.4,1099.4h50.2l31.3,143.6v-143.6h48V1427h-50.2l-30.9-147.5V1427h-48.4V1099.4z"
      ></path>
    </g>
    <path
      fill="currentColor"
      d="M1080.7,509.1v93L732.6,950.1l-29-29l336.1-336.1V522l22.6-21C1069.3,494.5,1080.7,499.5,1080.7,509.1z
		 M1062.2,644.9l-22.6,21v66.6L819.5,952.7c-4.7-1.6-9.7-2.5-15-2.5c-25.6,0-46.3,20.7-46.3,46.3s20.7,46.3,46.3,46.3
		c25.6,0,46.3-20.7,46.3-46.3c0-5.1-0.9-10-2.4-14.6l232.3-232.3V653C1080.7,643.4,1069.3,638.4,1062.2,644.9z M783.5,668.1
		L560.3,445v-98.5l-22.6-21c-7-6.5-18.5-1.5-18.5,8.1V462l235.2,235.2L783.5,668.1z M1073.7,468l6-6V333.5c0-9.6-11.4-14.6-18.5-8.1
		l-22.6,21V445L677.4,806.2c-7.6-5.5-17-8.8-27.1-8.8c-3.4,0-6.8,0.4-10,1.1l-80-80v-58.6l-22.6-21c-7-6.5-18.5-1.5-18.5,8.1v88.5
		l88.9,88.9c-2.7,5.9-4.2,12.4-4.2,19.2c0,25.6,20.7,46.3,46.3,46.3c25.1,0,45.5-20,46.2-44.9L1073.7,468z M715.9,735.8L560.3,580.3
		v-57.2l-22.6-21c-7-6.5-18.5-1.5-18.5,8.1v87.1l167.5,167.5L715.9,735.8z M604.2,357.8l-81.5-81.5V140.5c0-12,14.8-17.8,22.9-9
		l85.2,92.2h341.5l85.2-92.2c8.2-8.8,22.9-3.1,22.9,9v135.8l-81.5,81.5v61.1c0,17.2-14,31.2-31.2,31.2h-61.1L801.7,554.9l-0.2-0.2
		l-0.2,0.2L696.4,450.1h-61.1c-17.2,0-31.2-14-31.2-31.2V357.8z M662.3,416l-24-24v24H662.3z M964.6,391.9l-24,24h24V391.9z
		 M801.5,497.2l237.9-237.9v-48l-49.2,53.3H612.8l-49.2-53.3v48L801.5,497.2z"
    ></path>
  </g>
</svg>
