<script lang="ts">
  let clazz = "";
  export { clazz as class };
</script>

<svg
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class={clazz}
>
  <rect
    x="6.04492"
    y="5.07544"
    width="34.8511"
    height="2.41108"
    transform="rotate(45 6.04492 5.07544)"
    fill="currentColor"
  ></rect>
  <circle
    cx="5.26953"
    cy="5.85024"
    r="3.72622"
    transform="rotate(45 5.26953 5.85024)"
    fill="currentColor"
  ></circle>
  <circle
    cx="29.9131"
    cy="30.4933"
    r="3.72622"
    transform="rotate(45 29.9131 30.4933)"
    fill="currentColor"
  ></circle>
  <rect
    x="4.56836"
    y="28.9236"
    width="35"
    height="3"
    transform="rotate(-45 4.56836 28.9236)"
    fill="currentColor"
  ></rect>
  <circle
    cx="5.27553"
    cy="30.0183"
    r="3.72622"
    transform="rotate(-45 5.27553 30.0183)"
    fill="currentColor"
  ></circle>
  <circle
    cx="30.0246"
    cy="5.26953"
    r="3.72622"
    transform="rotate(-45 30.0246 5.26953)"
    fill="currentColor"
  ></circle>
</svg>
